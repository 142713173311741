export default {
  readonly: false,
  allowResizeY: true,
  editorCssClass: 'textEditor',
  height: 400,
  theme: 'vable',
  placeholder:
    "<span style='font-size: 18px;'><strong>Opportunity Description</strong></span><br><br><strong>Give potential volunteers more information about the position.</strong><br><br>What's expected? Who will they report to? Will they be working with other volunteers or is the work performed solo?<br><br>Format your text however you'd like. Add a heading. Insert a list of tasks. Add your organization's logo. Maybe add a link to your organization's about page. Anything that you think might be helpful to <u>catch the eye</u> of a potential volunteer!",
  buttons: [
    'source',
    'fullsize',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
  ],
  // window size < 940 px
  buttonsMD: [
    'source',
    'fullsize',
    '|',
    'bold',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
    'dots',
  ],
  // window size < 740px
  buttonsSM: [
    'source',
    'fullsize',
    '|',
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'dots',
  ],
  // window size < 440px
  buttonsXS: [
    // 'source',
    'bold',
    'image',
    '|',
    'brush',
    'paragraph',
    'eraser',
    '|',
    'align',
    '|',
    'undo',
    'redo',
    '|',
    'dots',
  ],
};
